// src/LogoCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { Box, Grid, Typography } from "@mui/material";
import "./LogoCarousel.css"

const LogoCarousel = () => {
  const logos = [
    { id: 1, src: './partner1.png', alt: 'Logo 1' },
    { id: 2, src: './partner2.png', alt: 'Logo 2' },
    { id: 3, src: './partner3.png', alt: 'Logo 3' },
    { id: 4, src: './partner4.jpg', alt: 'Logo 4' },
    { id: 5, src: './partner5.jpg', alt: 'Logo 5' },
    // Add more logos as needed
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: false, // Set autoplay speed to 0 for seamless effect
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
<Box>
      <Box
       
        sx={{
          bgcolor: "#DDD7C4",
          
          placeItems: "center",
           
        }}
      >
        <Box textAlign="center">
          <Typography
            fontFamily="Archivo"
            paddingTop={2}
            sx={{
              color: "#0D1039",
              fontWight: "bold",
              fontSize: { xs: "23px", md: "32px" },
              pb: { xs: 0, md: 2 },
              fontWeight: "bold",
            }}
          >
            OUR INCUBATOR PARTNERS
          </Typography>
        </Box>
         <div className="carousel-container">
          <Slider {...settings}>
          {logos.map((logo) => (
          <div key={logo.id} className="logo-slide">
           <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
           ))}
          </Slider>
          </div>
         
      </Box>
    </Box>








    // <div className="carousel-container">
    //   <Slider {...settings}>
    //     {logos.map((logo) => (
    //       <div key={logo.id} className="logo-slide">
    //         <img src={logo.src} alt={logo.alt} className="logo-image" />
    //       </div>
    //     ))}
    //   </Slider>
    // </div>
  );
};

export default LogoCarousel;

import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { scroller } from "react-scroll";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {/* about */}
      <Box
        id="about"
        sx={{
          bgcolor: "#0D1039",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box>

          <img
            src="./assets/frame.png"
            alt="image"
            style={{
              position: "absolute",
              top: 0,
              width: "50%",
              opacity: "0.6",
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 8 },
          }}
          alignItems="center"
        >
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box

              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2rem 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <Typography color="#fff" variant="h4" fontFamily="Archivo">
                  About Us{" "}
                </Typography>
                <Typography
                  mt={3}
                  color="#fff"
                  fontFamily="Archivo"
                  fontSize="1.3rem"
                  sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                >
                  Welcome to The Lions' Den, the ultimate stage where ambition meets opportunity. This groundbreaking spot-funding reality show brings together bold entrepreneurs and powerful investors, ready to turn visionary ideas into reality. Here, founders pitch their dreams to our "Lions" – industry giants who offer not only funding but also mentorship, expertise, and an invaluable network.

                  <br /><br />  It’s more than just a pitch – it’s a chance to transform potential into power. Dive into the journey, witness the high-stakes decisions, and see what it takes to roar in the world of business.

                  <br /><br />  The Lions' Den – Unleash your dreams, amplify your impact.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/about.png"
              width="100%"
              height="100%"
              alt="image"
            />
          </Box>
        </Box>
      </Box>



      <Box

        id="about"
        sx={{
          bgcolor: "#0D1039",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            fontFamily="Archivo"
            sx={{ color: "#fff", fontWeight: "bold", fontSize: "32px" }}
            data-aos="zoom-out"
            data-aos-duration="1500"
          >
            MEDIA COVERAGE
          </Typography>

          <img src="./vector.svg" alt="image" width="20%" />
        </Box>

        <Box

          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >

          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/article.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href="https://news.abplive.com/brand-wire/the-lions-den-roars-to-life-promising-a-fair-play-in-the-jungle-of-startup-funding-1689755">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}>Tired of Failing to Secure Funds, Entrepreneurs Launch Their Own Spot Funding Reality Show Named The Lions' Den</p></b>“After repeatedly getting ghosted by the investor following deal commitment, this brother duo from Gujarat Launch "The Lions' Den" spot Funding Reality Show set to release on JioCinema OTT.” In an exclusive interview with Mid-day, Savan Sanandiya, the Founder of The Lions' Den.....Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>


        </Box>

        {/* Jay */}
        <Box

          sx={{
            display: { xs: "flex", lg: "flex" },
      flexDirection: { xs: "column", lg: "row-reverse" },

            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >
          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/Jay.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href="https://m.dailyhunt.in/news/india/english/loktej+english-epaper-loktejen/the+visionary+transforming+ban+labs+and+shaping+the+future+of+business+joins+the+lions+den-newsid-n607152346?sm=Y">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}>  The Visionary Transforming Ban Labs and Shaping the Future of Business joins The Lions' Den </p>  </b> Rajkot (Gujarat) [India], May 9: At the youthful age of 29, Jay Ukani has already made an indelible mark as the Director of Ban Labs. His leadership is characterised by a bold vision that has seen the company expand from its Ayurvedic heritage to a diverse array of sectors, including Nutraceuticals, Health & Hygiene care, Personal care and fragrances, Stationery, and Apparel......Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>




        </Box>
        {/* poonam */}
        <Box

          sx={{
            display: { xs: "grid", lg: "flex" },

            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >

          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/Poonam.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href ="https://news.abplive.com/brand-wire/sustainability-meets-success-poonam-gupta-joins-the-lion-s-den-as-an-investor-1706602">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}>  Sustainability Meets Success: Poonam Gupta Joins The Lion's Den as an Investor </p></b>  Her focus on recycling and waste reduction in the paper industry demonstrates her dedication to environmental stewardship <br/> In an exciting development for aspiring entrepreneurs and eco-conscious innovators, renowned businesswoman Dr. Poonam Gupta is set to join The Lion's Den Show as its newest investor. With her remarkable journey from a £1000 startup to a £100 million international empire, Dr. Gupta brings a wealth of experience, a passion for sustainable business practices, and a deep commitment to philanthropy to the popular reality show.......Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>


        </Box>
        {/* Gaurav */}
        <Box

          sx={{
            display: { xs: "flex", lg: "flex" },
            flexDirection: { xs: "column", lg: "row-reverse" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >



          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/Gaurav.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href="https://news.abplive.com/brand-wire/serial-investor-gaurav-singhvi-joins-the-lion-s-den-show-aims-to-democratise-entrepreneurship-1706906">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}>Serial Investor Gaurav Singhvi Joins The Lion's Den Show, Aims to Democratise Entrepreneurship </p></b> With his extensive experience in investing and nurturing startups, Mr. Singhvi brings a fresh perspective <br/> New Delhi (India) July 30: India's startup ecosystem is rapidly growing and has long needed a catalyst to democratise entrepreneurship across the nation's diverse landscape. The concentration of opportunities in major metropolitan areas has left a wealth of talent and innovation untapped in smaller cities and towns.......Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Vinita */}
        <Box

          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >

          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/Vinita.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href="https://news.abplive.com/brand-wire/vinita-surana-roars-into-the-lion-s-den-a-new-chapter-in-startup-investing-1714111">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}> Vinita Surana Roars Into The Lion’s Den: A New Chapter In Startup Investing </p></b> Vinita’s investment strategy blends an analytical approach with gut instinct, focusing on three key factors: the founder’s vibe, product scalability, and total addressable market <br/> India’s most thrilling business reality show, “The Lion’s Den,” gains a powerhouse with the arrival of Ms. Vinita Surana on its panel of esteemed investors, popularly known as the Lions. A formidable force in India’s entrepreneurial landscape, Vinita brings her wealth of experience as a seasoned investor and serial entrepreneur to the den. ......Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>


        </Box>
        {/* deepak */}
        <Box

          sx={{
            display: { xs: "flex", lg: "flex" },
            flexDirection: { xs: "column", lg: "row-reverse" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 2 },
          }}
          alignItems="center"
        >

          <Box
            style={{ textAlign: "center" }}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/Deepak.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{ border: "13px solid rgb(245, 189, 30)" }}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <a style={{ textDecoration: "none" }} href="https://www.uniindia.net/the-lions-39-den-show-welcomes-dipak-sanghavi-as-the-investor-to-shape-the-future-of-start-45-ups/pnn/news/3201449.html">
                  <Typography
                    mt={3}
                    color="#fff"
                    fontFamily="Archivo"
                    fontSize="1.3rem"
                    sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                  >
                    <b><p style={{ margin: "0px", }}>  The Lions' Den Show Welcomes Dipak Sanghavi as the Investor to Shape the Future of Start-Ups  </p> </b> Only 2 percent of start-ups survive, and less than 0.2 percent scale up Mumbai (Maharashtra) [India], May 17:    When the Lions' Den, an upcoming reality show, was conceptualising, the key question they had to answer was, " What problem are we trying to solve? "The answer to this question would define the design of the show, the method of evaluating start-ups, and most importantly, the Lions they would partner with, who will define the success of the entire effort in the mid-to-long-term.  ......Read More
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>


        </Box>


      </Box>
    </>
  );
};

export default About;
